import { IMediaAssetsSettings } from "@ecp-boxes/boxes/BoxSearchResults/BoxSearchResults.types";
import styled, { css } from "styled-components";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { IMediaAsset } from "@ecp-redux/api/mediaAssets";

export const StyledMediaAsset = styled.div<{
  assetSettings: IMediaAssetsSettings | undefined;
  asset: IMediaAsset | undefined;
}>(({ assetSettings, asset, theme }) => {
  return css`
    order: ${assetSettings?.iconPosition === "LEFT" ? -1 : 1};

    .media-asset {
      ${asset?.color &&
      css`
        &__color {
          background-color: ${convertColorIdToHex(
            asset.color ?? `#000000`,
            theme.colorPalette
          )};

          order: ${assetSettings?.iconPosition === "LEFT" ? -1 : 1};

          width: ${assetSettings?.iconSize.auto
            ? `24`
            : assetSettings?.iconSize.height}px;

          height: ${assetSettings?.iconSize.auto
            ? `24`
            : assetSettings?.iconSize.height}px;

          border-radius: ${assetSettings?.borderRadius}%;
        }
      `}
    }
  `;
});
