import { ReactNode } from "react";
import { IMediaAssetsSettings } from "@ecp-boxes/boxes/BoxSearchResults/BoxSearchResults.types";
import { StyledAttributeWithMediaAsset } from "./ProductModule.styled";
import MediaAsset from "@ecp-boxes/shared/components/MediaAsset/MediaAsset";
import { IMediaAsset } from "@ecp-redux/api/mediaAssets";

interface IAttributeWithMediaAssetProps {
  assetSettings: IMediaAssetsSettings | undefined;
  mediaAsset: IMediaAsset | undefined;
  children?: ReactNode;
}

const AttributeWithMediaAsset: React.FC<IAttributeWithMediaAssetProps> = ({
  assetSettings,
  mediaAsset,
  children,
}) => {
  const iconOnly = assetSettings?.pureIcon === true;
  return (
    <StyledAttributeWithMediaAsset
      className="media-asset-container"
      assetSettings={assetSettings}
    >
      {iconOnly === false && children}
      <MediaAsset assetSettings={assetSettings} mediaAsset={mediaAsset} />
    </StyledAttributeWithMediaAsset>
  );
};

export default AttributeWithMediaAsset;
