import { IMediaAssetsSettings } from "@ecp-boxes/boxes/BoxSearchResults/BoxSearchResults.types";

export interface IMediaAssetImageSizes {
  height: number;
  width: number;
}

export const getImageSizes = (
  imageSize: IMediaAssetImageSizes,
  assetSettings?: IMediaAssetsSettings
) => {
  return assetSettings?.iconSize.auto === false
    ? {
        height: assetSettings.iconSize.height,
        width: assetSettings.iconSize.height,
      }
    : { height: imageSize.height, width: imageSize.width };
};
