import { IMediaAssetsSettings } from "@ecp-boxes/boxes/BoxSearchResults/BoxSearchResults.types";
import { StyledMediaAsset } from "./MediaAssets.styled";
import { useState, useEffect } from "react";
import { convertUrlToImage } from "@ecp-boxes/helpers/helpers";
import { getImageSizes } from "./MediaAsset.methods";
import ImageWrapper from "@ecp-boxes/global/components/ImageWrapper/ImageWrapper";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IMediaAsset } from "@ecp-redux/api/mediaAssets";

interface IMediaAssetProps {
  assetSettings: IMediaAssetsSettings | undefined;
  mediaAsset: IMediaAsset | undefined;
}

const MediaAsset: React.FC<IMediaAssetProps> = ({
  assetSettings,
  mediaAsset,
}) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const assetImageUrl = mediaAsset?.image?.defaultIconUrl;

  const assetType = mediaAsset?.mediaAssetsType;

  const calculatedImageSize = getImageSizes(imageSize, assetSettings);

  useEffect(() => {
    if (!assetImageUrl) return;

    let isMounted = true;

    convertUrlToImage(assetImageUrl).then((img) => {
      if (isMounted && img) {
        setImageSize({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      }
    });

    return () => {
      isMounted = false;
    };
  }, [assetImageUrl]);

  return (
    <StyledMediaAsset
      className="media-asset"
      assetSettings={assetSettings}
      asset={mediaAsset}
    >
      {assetType === "COLOR" && <div className="media-asset__color" />}
      {assetType === "IMAGE" && (
        <div className="media-asset__image">
          <ImageWrapper
            imageUrl={assetImageUrl ?? ""}
            seoDescription="image-media-asset"
            imageFit={false}
            imageAlignment={{
              horizontal: AlignmentHorizontalOption.CENTER,
              vertical: AlignmentVerticalOption.CENTER,
            }}
            height={calculatedImageSize.height}
            width={calculatedImageSize.width}
          />
        </div>
      )}
    </StyledMediaAsset>
  );
};

export default MediaAsset;
